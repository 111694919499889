import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Parser } from 'html-to-react';
import SocialIcons from '../ui/SocialIcons';
import { Card, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { formatListMapping } from '../../static/utility';
import ArchIcon from '../ui/ArchIcon';


function CreatorDetails({ userProfile }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const sections = ['ugcTypes', 'industries', 'specialties', 'platform', 'process', 'pricing'];
  const sectionTitles = {
    'ugcTypes': 'UGC Types',
    'industries': 'Industries',
    'specialties': 'Specialties',
    'process': 'Process',
    'platform': 'Platform',
    'pricing': 'Pricing',
  };
  const sectionIcon = {
    'ugcTypes': <ArchIcon color={'#F3AFA8'} />,
    'industries': <ArchIcon color={'#FF6250'} />,
    'specialties': <ArchIcon color={'#009379'} />,
    'process': <ArchIcon color={'#F3F3F3'} />,
    'platform': <ArchIcon color={'#F7D684'} />,
    'pricing': <ArchIcon color={'#2D2D2D'} />,
  }
  const SectionCard = ({ type }: { type: string }) => {
    return (
      <Card sx={{ borderColor: 'transparent', boxShadow: 'none', paddingX: 10, paddingY: 5 }} >
        <Stack direction={'row'} alignItems={'start'} justifyItems={'center'} spacing={8}>
          {sectionIcon[type]}
          <Stack direction={'column'} spacing={4}>
            <Typography variant={'h6'} >{sectionTitles[type]}</Typography>
            <Typography variant={'body2'} >{
              (Array.isArray(userProfile[type])) ? formatListMapping(userProfile[type], type).join(', ') : userProfile[type]
            }</Typography>
          </Stack>
        </Stack>
      </Card>
    );
  }


  return (
    <Grid
      container
      direction="row"
      marginY={isMobile ? 10 : 20}
    >
      {sections.map((section) => {
        return (
          <Grid item xs={12} sm={6}>
            <SectionCard type={section} />
          </Grid>
        )
      })

      }

    </Grid>
  )

}
export default CreatorDetails;

