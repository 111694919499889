import { Box, Button, Grid, Typography, Stack, useMediaQuery } from '@mui/material';
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import LogoDark from '../assets/images/UGCC_Logo_white.png';

export default function LandingPage({portis, Userfront}) {
  const theme = useTheme(); 
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {    
    if (Userfront.user.userUuid){
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [Userfront]);

  /*useEffect(() => {
    const runAsync = async () => {
      const _isLoggedIn = await portis.isLoggedIn();
      if (!_isLoggedIn.result) { 
        setIsLoggedIn(false);
        //await portis.showPortis();
      } else {
        setIsLoggedIn(true);
      }
    }
    runAsync();

    portis.onLogin((walletAddress, email, reputation) => {
      console.log(walletAddress, email, reputation);
      setIsLoggedIn(true);
    });

    portis.onLogout(() => {
      setIsLoggedIn(false);
    });

  }, [isLoggedIn])*/
  

  return (
    <Box sx={{pt:25, backgroundColor: '#071F4E', minHeight: '95vh', overflowY: 'hidden', px: 10}} >

      <img src={LogoDark} alt="UGCC" className={isMobile? 'landing-logo_mobile' : 'landing-logo'}/>
      <Typography variant={isMobile? 'h4' : 'h3'} align='center' color='white' sx={{fontFamily: 'Spinnaker'}}>Your instant</Typography>
      <Typography variant={isMobile? 'h3' : 'h2'} align='center' color='white' sx={{fontFamily: 'Spinnaker'}}>UGC Portfolio</Typography>
      <Typography variant={isMobile? 'body1' : 'h5'} align='center' color='white' sx={{fontFamily: 'Spinnaker', mt:10}}>
        Get more brand deals with a professional UGC
      </Typography>
      <Typography variant={isMobile? 'body1' : 'h5'} align='center' color='white' sx={{fontFamily: 'Spinnaker'}}>
        portfolio. Reserve your portfolio - it's free!
      </Typography>     

      {!isLoggedIn && 
        <Grid 
            container={true}
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '25vh' }}>
            {/*<IconButton aria-label="addRecord" onClick={newChannel}>
              <AddIcon />
            </IconButton>*/}
            <Grid item xs={3}>
              <Stack spacing={5} direction={'column'}>
              <Button component={Link} to={'/signup'}  variant="contained" color={theme.palette.mode === 'light' ? 'primary' : 'primary'} sx={{fontSize: '1.25rem', paddingX: 25, color:'#fff'}}>SignUp</Button>
              <Button component={Link} to={'/login'}  variant="outlined" color={theme.palette.mode === 'light' ? 'primary' : 'primary'} sx={{fontSize: '1.25rem', paddingX: 25, color:'primary'}}>Login</Button>              
              </Stack>
              
            </Grid>
        </Grid>
        
       
        //<Button onClick={() => portis.showPortis()} style={{marginLeft:'auto', marginRight:'auto', display:'block', fontWeight: 600, fontSize: '80%', borderRadius: '100rem'}} variant="contained">Login</Button>
      }
      {isLoggedIn && 
        <Grid 
            container={true}
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '25vh' }}>
            {/*<IconButton aria-label="addRecord" onClick={newChannel}>
              <AddIcon />
            </IconButton>*/}
            <Grid item xs={3}>
              <Button component={Link} to={'/dashboard'}  variant="contained" color={theme.palette.mode === 'light' ? 'primary' : 'primary'} sx={{fontSize: '1.25rem', paddingX: 25, color:'#fff'}}>Dashboard</Button>
            </Grid>
        </Grid>
        
        //<Button onClick={() => portis.showPortis()} style={{marginLeft:'auto', marginRight:'auto', display:'block', fontWeight: 600, fontSize: '80%', borderRadius: '100rem'}} variant="contained">Login</Button>
      }
    </Box>
  )
}