import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Parser } from 'html-to-react';
import SocialIcons from '../ui/SocialIcons';
import { Stack } from '@mui/material';


function CreatorFooter({ userProfile }) {
  const [tiktokUrl, setTikTokUrl] = useState('');
  const [email, setEmail] = useState('');
  const [twitter, setTwitter] = useState('');
  const [youtube, setYoutube] = useState('');
  const [instagram, setInstagram] = useState('');
  useEffect(() => {
    if (userProfile.tiktokUrl) {
      setTikTokUrl(userProfile.tiktokHandle.includes('@') ? 'https://www.tiktok.com/' + userProfile.tiktokHandle : 'https://www.tiktok.com/@' + userProfile.tiktokHandle);
    }
    if (userProfile.twitterHandle) {
      setTwitter('https://www.twitter.com/' + userProfile.twitterHandle);
    }
    if (userProfile.youtubeHandle) {
      setYoutube('https://www.youtube.com/' + userProfile.youtubeHandle);
    }
    if (userProfile.instagramHandle) {
      setInstagram('https://www.instagram.com/' + userProfile.instagramHandle);
    }
    if (userProfile.email) {
      setEmail('mailto:' + userProfile.email);
    }
  }, [userProfile])


  return (
    <Stack my={10} direction={'column'} spacing={8}>
      {/*<Typography variant='h5' fontWeight={600}>Lets work together</Typography>
      <Typography variant="body2">I'm ready to jump in and create new, authentic content. Lets connect and get started!</Typography>*/}
      <SocialIcons
        overrideColor={''}
        twitterHandle={twitter}
        email={email}
        websiteUrl={userProfile.website}
        youtubeHandle={youtube}
        instagramHandle={instagram}
        tiktokHandle={tiktokUrl}
      ></SocialIcons>
    </Stack>
  )

}
export default CreatorFooter;

