import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
//import { useEtherBalance, useEthers } from '@usedapp/core';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import { Image } from '@mui/icons-material';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MenuIcon from '@mui/icons-material/Menu';
import BookmarksIcon from '@mui/icons-material/BookmarksOutlined';
import Divider from '@mui/material/Divider';
import SubscriptionsIcon from '@mui/icons-material/SubscriptionsOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import StorageIcon from '@mui/icons-material/Storage';
import GavelIcon from '@mui/icons-material/Gavel';
import { IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SocialIcons from './ui/SocialIcons';
import { ethers } from 'ethers';

import LogoLight from '../assets/images/UGCC_Logo.png';
import { grey } from '@mui/material/colors';

function MenuLinks({ portis, web3Provider, ui, Userfront }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [provider, setProvider] = React.useState({});
  const [state, setState] = React.useState({
    top: false,
    left: true,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    const runAsync = async () => {
      console.log('web3 provider changed');


      //const web3Provider = new ethers.providers.Web3Provider(portis.provider);
      portis.onLogin((walletAddress, email, reputation) => {
        console.log(walletAddress, email, reputation);
        setIsLoggedIn(true);
      });

      portis.onLogout(() => {
        setIsLoggedIn(false);
      });
    }
    //runAsync();
  }, [web3Provider, portis]);

  useEffect(() => {
    if (Userfront) {
      console.log('userData', Userfront.user);
      if (Userfront.user.userUuid) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    }

  }, [Userfront])

  const toggleMenu = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const menuLinkList = [
    {
      name: 'Dashboard',
      link: '/creator'
    },
    {
      name: 'Public Profile',
      link: '/' + Userfront.user.userUuid
    }
  ];
  const menuLinkList_noAuth = [
    {
      name: 'Home',
      link: '/'
    }
  ];

  const list = (anchor) => (
    <Box
      sx={{ width: 250, mt: 6, bgcolor: 'transparent', flexGrow: 1, display: 'inline-block', zIndex: 2000 }}
      role="presentation"
    >

      <Box sx={{ mx: 'auto' }}>
        {theme.palette.mode === 'dark' &&
          <img
            src={LogoLight}
            className="side-image"
            alt="menuLogo"
          />
        }
        {theme.palette.mode === 'light' &&
          <img
            src={LogoLight}
            className="side-image"
            alt="menuLogo"
          />
        }

      </Box>

      <List sx={{ ml: 8, mt: 10 }}>

        {menuLinkList && isLoggedIn &&
          (menuLinkList.map((linkVal) => (
            <ListItem button component={Link} to={linkVal.link} key={linkVal.name}>
              <ListItemIcon sx={{ mr: -8 }}>
                {linkVal.name === 'Dashboard' &&
                  <SpeedIcon />
                }
                {linkVal.name === 'Channels' &&
                  <StorageIcon />
                }
                {linkVal.name === 'Home' &&
                  <BookmarksIcon />
                }
                {linkVal.name === 'Public Profile' &&
                  <BookmarksIcon />
                }
              </ListItemIcon>
              <ListItemText primary={linkVal.name} sx={{}} />
            </ListItem>
          )))
        }

        {menuLinkList_noAuth && !isLoggedIn &&
          (menuLinkList_noAuth.map((linkVal) => (
            <ListItem button component={Link} to={linkVal.link} key={linkVal.name}>
              <ListItemIcon sx={{ mr: -8 }}>
                {linkVal.name === 'Dashboard' &&
                  <SpeedIcon />
                }
                {linkVal.name === 'Channels' &&
                  <StorageIcon />
                }
                {linkVal.name === 'Home' &&
                  <BookmarksIcon />
                }
                {linkVal.name === 'Public Profile' &&
                  <StorageIcon />
                }
              </ListItemIcon>
              <ListItemText primary={linkVal.name} sx={{}} />
            </ListItem>
          )))
        }

        <Divider sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            my: 20
          },
          mr: 8,
          borderWidth: 2,
          backgroundColor: theme.palette.grey[200],
          borderColor: theme.palette.grey[200]
        })} />

        {
          /*
            <ListItem button onClick={() => portis.showPortis()} key="manage-link" sx={{fontSize: '.5rem'}}>   
              <ListItemText primary="Settings" sx={{fontSize: '.5rem'}} className="secondary-links"/>
            </ListItem>
          */
        }


        <ListItem button component="a" target="_blank" href="https://www.ugcc.xyz/help" key="help-link">
          <ListItemText primary="Have a question?" className="secondary-links" sx={{ textAlign: 'center' }} />
        </ListItem>

        <ListItem>
          <SocialIcons
            twitterHandle="https://www.twitter.com"
            email="bdomnie@gmail.com"
            githubUrl="https://www.github.com"
            websiteUrl="https://www.ugcc.xyz"
          ></SocialIcons>
        </ListItem>
      </List>
      {isLoggedIn &&
        <Box sx={{ position: 'absolute', bottom: "2vh", width: "100%" }}>
          <Button onClick={() => Userfront.logout()} style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', fontWeight: 600, fontSize: '80%', borderRadius: '100rem' }} variant="contained" color={theme.palette.mode === 'light' ? 'secondary' : 'primary'}>Logout</Button>
        </Box>
      }

    </Box>
  );

  return (
    <div style={{ display: 'inline-block' }}>
      {/*<MenuIcon onClick={toggleMenu('left', true)} sx={{verticalAlign: 'middle'}}></MenuIcon>*/}
      {isLoggedIn && !isMobile &&
        <Drawer
          anchor={'left'}
          open={state['left']}
          variant="persistent"
          onClose={toggleMenu('left', false)}
          sx={{ border: 'none', overflowX: 'hidden' }}
          PaperProps={{
            elevation: 4,
            sx: { border: 'none', boxShadow: 'none', overflowX: 'hidden' }
          }}
        >
          {list('left')}
        </Drawer>
      }

      {!isLoggedIn && !isMobile &&
        <Drawer
          anchor={'left'}
          open={state['left']}
          variant="persistent"
          onClose={toggleMenu('left', false)}
          sx={{ overflowX: 'hidden' }}
          PaperProps={{
            elevation: 4,
            sx: { border: 'none', boxShadow: 'none', overflowX: 'hidden' }
          }}
        >
          <Box
            sx={{ width: 250, height: '100vh' }}
            role="presentation"
          ></Box>
        </Drawer>
      }

      {isLoggedIn && isMobile &&
        <>
          <IconButton onClick={() => { setOpenDrawer(!openDrawer) }}><MenuIcon sx={{ color: grey[400] }} /></IconButton>
          <Drawer
            anchor={'left'}
            open={openDrawer}
            onClose={() => { setOpenDrawer(false) }}
            sx={{ border: 'none', overflowX: 'hidden' }}
            PaperProps={{
              elevation: 4,
              sx: { border: 'none', boxShadow: 'none', overflowX: 'hidden' }
            }}
          >
            {list('left')}
          </Drawer>
        </>
      }
    </div>
  )
}
export default MenuLinks;