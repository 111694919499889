import React from "react";
type Props = {
  children: React.ReactNode;
}
export default class ErrorBoundary extends React.Component<Props>  {
  state = { error: false, errorMessage: '' };

  static getDerivedStateFromError(error) {
    // Update state to render the fallback UI
    return { error: true, errorMessage: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to an error reporting service like Sentry
    console.log({ error, errorInfo });
  }

  render() {
    const { error, errorMessage } = this.state;
    const { children } = this.props;

    return error ? <>Sorry Error Loading Post</>: children;
  }
}