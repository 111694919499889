import React, { useEffect, useState, useRef } from 'react';
import logo from './logo.png';
import Box from '@mui/material/Box';
import Portis from '@portis/web3';
import { ethers } from 'ethers';
import Userfront from "@userfront/react";

import { env } from './static/constants';
import Header from './components/Header';
import LandingPage from './pages/Landing';
import ProfilePage from './pages/Profile';
import DashboardPage from './pages/Dashboard';
import LoginPage from './pages/auth/Login';
import SignupPage from './pages/auth/Signup';
import ResetPage from './pages/auth/Reset';

import './App.css';

import { BrowserRouter, Routes, Route, HashRouter, useLocation, } from 'react-router-dom';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

Userfront.init(env.REACT_APP_USERFRONT_API_KEY);

const path_list = ['/', '/login', '/signup', '/reset', '/dashboard', '/creator'];

function App() {
  useEffect(() => {
    document.title = "UGCC";
  }, []);

  const location = window.location;
  console.log(location.pathname);

  const MainBox = styled(Box)(({ theme }) => ({
    bgcolor: 'text.disabled',
    display: 'block',
    height: '100%',
    minHeight: '100vh',
    overflowX: 'hidden',
    backgroundColor: theme.palette.mode === 'dark' ? '#282c34' : '#00000',
    marginTop: '2rem',
    [theme.breakpoints.up('md')]: {
      marginLeft: Userfront.user.userUuid ? '250px' : '0px'
    }
  }));

  const [openDrawer, setOpenDrawer] = React.useState('');
  const [mdTheme, setMdTheme] = React.useState(createTheme({
    spacing: 2,
    palette: {
      mode: 'light',
      primary: {
        main: '#5F71E4'
      },
      secondary: {
        main: '#2D2D2D'
      }
    },
    typography: {
      fontFamily: [
        'Montserrat',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  }));
  const setTheme = (value) => {
    setMdTheme(createTheme({
      spacing: 2,
      palette: {
        mode: 'light',
        primary: {
          main: '#5F71E4'
        },
        secondary: {
          main: '#2D2D2D'
        }
      },
      typography: {
        fontFamily: [
          'Montserrat',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },
    }));
    localStorage.setItem("theme", value);
  }
  //const portis = new Portis(env.REACT_APP_PORTIS_API_KEY, 'mainnet');
  //const web3Provider = new ethers.providers.Web3Provider(portis.provider);

  return (
    <ThemeProvider theme={mdTheme}>
      <BrowserRouter>
        <Box sx={{ backgroundColor: mdTheme.palette.mode === 'dark' ? '#0B1C4D' : '#00000', overflowY: Userfront.user.userUuid ? 'none' : 'hidden', }}>
          {
            path_list.reduce((finalVal, p) => {
              if (p === location.pathname) {
                return true;
              }
              return finalVal;
            }, false) &&
            <Header updateTheme={setTheme} setOpenDrawer={setOpenDrawer} portis={null} web3Provider={null} Userfront={Userfront} />
          }


          <Routes >
            <Route path='/' element={<LandingPage portis={null} Userfront={Userfront} />} />
            <Route path='login' element={<LoginPage Userfront={Userfront} />} />
            <Route path='signup' element={<SignupPage Userfront={Userfront} />} />
            <Route path='reset' element={<ResetPage Userfront={Userfront} />} />
            <Route path="dashboard" element={<MainBox><DashboardPage portis={null} web3Provider={null} Userfront={Userfront} /></MainBox>} />
            <Route path="creator" element={<MainBox><DashboardPage portis={null} web3Provider={null} Userfront={Userfront} /></MainBox>} />
            <Route path=':creatorId' element={<ProfilePage />} />
          </Routes>

        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
