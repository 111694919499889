import { AppBar, Avatar, Box, Button, Card, CardActions, CardContent, Chip, Divider, Grid, List, ListItem, ListItemText, Stack, Tab, Tabs, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import CreatorDetailCard from '../components/portfolio/CreatorDetailCard';
import { env } from '../static/constants';
import { getPortfolio, getProfile, formatListMapping, ugcTypeMapping } from '../static/utility';
import SocialIcons from '../components/ui/SocialIcons';
import FeedItem from '../components/portfolio/FeedItem';
import ArchImage from '../components/ui/ArchImage';
import CreatorBanner from '../components/portfolio/CreatorBanner';
import portis from '@portis/web3';
import MenuLinks from '../components/Menu';
import LogoDark from '../assets/images/UGCC_Logo_white.png';
import LogoLight from '../assets/images/UGCC_Logo_Purple_Cropped.png';
import LogoFace from '../assets/images/UGCFace.png';
import CreatorFooter from '../components/portfolio/CreatorFooter';
import CreatorDetails from '../components/portfolio/CreatorDetails';
import EmptySpace from '../components/ui/EmptySpace';


export default function ProfilePage({ }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { creatorId } = useParams();

  const [_walletAddress, setWalletAddress] = useState('');
  const [userProfile, setUserProfile] = useState({
    chainId: 1,
    creatorName: null,
    email: null,
    image: null,
    industries: null,
    instagramHandle: null,
    intro: null,
    phone: null,
    platform: null,
    pricing: null,
    process: null,
    specialties: null,
    tiktokHandle: null,
    twitterHandle: null,
    ugcTypes: null,
    walletAddress: null,
    website: null,
    youtubeHandle: null,
  });
  const [portfolioList, setPortfolioList] = useState([]);

  const [tabIndex, setTabIndex] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const runAsync = async () => {
      const profileResponse = await getProfile(creatorId);
      if (profileResponse) {
        console.log(profileResponse);
        setUserProfile(profileResponse);
        const portfolioResponse = await getPortfolio(creatorId);
        console.log(portfolioResponse);
        setPortfolioList(portfolioResponse);
      }
    }

    if (creatorId) {
      runAsync();
    }
  }, [creatorId]);

  const refreshList = async () => {
    const portfolioResponse = await getPortfolio(creatorId);
    console.log(portfolioResponse);
    setPortfolioList(portfolioResponse);
  }

  const aboutRef = useRef(null);
  const workRef = useRef(null);
  const contactRef = useRef(null);

  function toAbout() {
    window.scrollTo({
      top: aboutRef.current.offsetTop,
      behavior: "smooth"
    })
  }

  function toWork() {
    window.scrollTo({
      top: workRef.current.offsetTop,
      behavior: "smooth"
    })
  }

  function toContact() {
    window.scrollTo({
      top: contactRef.current.offsetTop,
      behavior: "smooth"
    })
  }

  const ProfileHeader = () => {
    return (
      <Stack direction={'row'} justifyContent={isMobile ? 'center' : 'space-between'} alignItems={'center'} width={'100%'}>
        {/*<Button onClick={toHome} sx={{}}>
          <img src={LogoFace} alt="UGCC Portfolio" className="header-image" />
        </Button>*/}
        <EmptySpace />
        <Stack direction={'row'} spacing={10} >
          <Button variant="text" color={'secondary'} onClick={toAbout} sx={{ textTransform: 'none' }}>About</Button>
          <Button variant="text" color={'secondary'} onClick={toWork} sx={{ textTransform: 'none' }}>Work</Button>
          {/*<Button variant="text" color={'secondary'} onClick={toContact} sx={{ textTransform: 'none' }}>Contact</Button>*/}
        </Stack>
      </Stack>
    )
  }

  return (
    <Box sx={{ py: isMobile ? 2 : 2, minHeight: '95vh', backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#121212', overflowX: 'hidden' }}>
      <script async src="www.instagram.com/embed.js"></script>

      {creatorId !== null &&
        <>
          <Grid
            container
            direction="row"
            sx={{ m: isMobile ? 20 : 10, }}
          >
            <Grid item xs={0} sm={2}></Grid>
            <Grid item xs={10} sm={8}>
              <ProfileHeader />
            </Grid>
            <Grid item xs={1} sm={2}></Grid>

            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={10} sm={8} ref={aboutRef}>
              <CreatorBanner userProfile={userProfile} />
            </Grid>
            <Grid item xs={1} sm={2}></Grid>

            {/*<Grid item xs={1} sm={2}></Grid>
            <Grid item xs={10} sm={8} ref={contactRef}>
              <CreatorFooter userProfile={userProfile} />
            </Grid>
            <Grid item xs={1} sm={2}></Grid>*/}

            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={10} sm={8}>
              <CreatorDetails userProfile={userProfile} />
            </Grid>
            <Grid item xs={1} sm={2}></Grid>

            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={10} sm={8} ref={workRef}>
              <Typography variant={'h6'} textAlign={'center'} sx={{ mt: 25 }}>Content Portfolio</Typography>
              <Grid container spacing={10} >
                {portfolioList && Array.isArray(portfolioList) &&
                  portfolioList.map((val, index) => {
                    return (
                      <Grid key={index} item xs={12} sm={6}>
                        <FeedItem portfolioItem={val} maxWidth={700} Userfront={null} refreshList={refreshList}></FeedItem>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Grid>
            <Grid item xs={1} sm={2}></Grid>

          </Grid>
        </>
      }
    </Box>
  )
}