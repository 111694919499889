import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Button, Chip, Toolbar, useMediaQuery } from '@mui/material';
import MenuLinks from './Menu';
import ThemeButton from './ThemeButton';
import LogoLight from '../assets/images/UGCC_Logo_Purple_Cropped.png';
import LogoDark from '../assets/images/UGCC_Logo_white.png';
import LogoFace from '../assets/images/UGCFace.png';

export default function HeaderPage({updateTheme, setOpenDrawer, portis, web3Provider, Userfront}) {
  //const {account} = useEthers();
  const [account, setAccount] = useState('');
  const [menuType, setMenuType] = useState('');
  const currentTheme = useTheme();
  const isMobile = useMediaQuery(currentTheme.breakpoints.down('sm'));
  let navigate = useNavigate();
  const location = useLocation();

  function menuHasChanged(menu) {
    let menuResponse = menu;
    if (menuResponse === 'creator' && !location.pathname.startsWith('/manage')) {
      //navigate("/");
    } else if (menuResponse === 'fan' && !location.pathname.startsWith('/channels')){
      //navigate("/");
    } else if (menuResponse === '' && location.pathname.startsWith('/manage')) {
      menuResponse = 'artist';
    } else if (menuResponse === '' && location.pathname.startsWith('/channels')) {
      menuResponse = 'fan';
    }

    setMenuType(menuResponse);
  }

  useEffect(() => {
    console.log(location);
  }, [location])

  function toHome() {
    navigate("/", { replace: true });
  }

  return (
    <div style={{display: 'flex'}}>
      {Userfront.user.userUuid && location.pathname === '/dashboard' && !isMobile && 
        <MenuLinks ui={menuType} portis={portis} web3Provider={web3Provider} Userfront={Userfront}/>
      }
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, boxShadow: 'none', backgroundColor: '#373737' }}>
          <Toolbar>           
            <Box sx={{ width: 250 }}>
              
              {!isMobile &&
                <Button onClick={toHome} sx={{ ml:2, display: 'block'}}>
                  {currentTheme.palette.mode === 'dark' && 
                    <img src={LogoDark} alt="UGCC Portfolio" className="header-image"/>
                  }
                  {currentTheme.palette.mode === 'light' && 
                    <img src={LogoDark} alt="UGCC Portfolio" className="header-image"/>
                  }
                </Button>
              }
              {Userfront.user.userUuid && location.pathname === '/dashboard' && isMobile && 
                <MenuLinks ui={menuType} portis={portis} web3Provider={web3Provider} Userfront={Userfront}/>
              }
              
              
            </Box>
            <Box sx={{ flexGrow: 6 }}>
              {
                //<SearchInput></SearchInput>
              }
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: 'right'  }}>
                {
                  <Chip icon={<img src={LogoFace} alt="UGCC Portfolio" className="chip-image"/>} label="Beta" sx={{fontWeight: 'bold', color: 'white', backgroundColor: 'transparent', borderColor: 'white', borderWidth: 1, borderStyle: 'solid', mr: 10}}/>
                  //<ThemeButton valueChange={updateTheme} switchType="theme"></ThemeButton>
                }
            </Box>        
          </Toolbar>
        </AppBar>
        
        {
          /*
          account && 
          <ChannelListSidebar></ChannelListSidebar>
          */
        }
      </Box>
    </div>
    
  );
}