import { Avatar, BottomNavigation, BottomNavigationAction, Box, Button, Card, CardActions, CardContent, Chip, Divider, Grid, List, ListItem, ListItemText, Stack, Tab, Tabs, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import LanguageIcon from '@mui/icons-material/Language';
import ShareIcon from '@mui/icons-material/Share';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import CreateProfile from '../components/CreateProfile';
import CreatePost from '../components/CreatePost';
import { env } from '../static/constants';
import { getPortfolio, getProfile, formatListMapping, ugcTypeMapping } from '../static/utility';
import SocialIcons from '../components/ui/SocialIcons';
import FeedItem from '../components/portfolio/FeedItem';


export default function DashboardPage({ portis, web3Provider, Userfront }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [_walletAddress, setWalletAddress] = useState('');
  const [userProfile, setUserProfile] = useState({
    chainId: 1,
    creatorName: null,
    email: null,
    image: null,
    industries: null,
    instagramHandle: null,
    intro: null,
    phone: null,
    platform: null,
    pricing: null,
    process: null,
    specialties: null,
    tiktokHandle: null,
    twitterHandle: null,
    ugcTypes: null,
    walletAddress: null,
    website: null,
    youtubeHandle: null,
  });
  const [portfolioList, setPortfolioList] = useState([]);

  const [tabIndex, setTabIndex] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const runAsync = async () => {
      const profileResponse = await getProfile(Userfront.user.userUuid);
      if (profileResponse) {
        //console.log(profileResponse);
        setUserProfile(profileResponse);
        const portfolioResponse = await getPortfolio(Userfront.user.userUuid);
        console.log(portfolioResponse);
        setPortfolioList(portfolioResponse);
      }
    }

    if (Userfront.user.userUuid) {
      setIsLoggedIn(true);
      setWalletAddress(Userfront.user.userUuid);
      runAsync();
    }
  }, [Userfront]);

  const refreshList = async () => {
    const portfolioResponse = await getPortfolio(_walletAddress);
    console.log(portfolioResponse);
    setPortfolioList(portfolioResponse);
  }

  const refreshPage = async () => {
    const profileResponse = await getProfile(Userfront.user.userUuid);
    if (profileResponse) {
      //console.log(profileResponse);
      setUserProfile(profileResponse);
      const portfolioResponse = await getPortfolio(Userfront.user.userUuid);
      console.log(portfolioResponse);
      setPortfolioList(portfolioResponse);
    }
  }

  const ProfileTabs = () => {
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="profile tabs" centered>
            <Tab icon={<InfoIcon sx={{ pr: 6 }} />} label="Basics" iconPosition="start" />
            <Tab icon={<DynamicFeedIcon sx={{ pr: 6 }} />} label="Content" iconPosition="start" />
          </Tabs>
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    if (openTooltip) {
      const timer = setTimeout(() => {
        setOpenTooltip(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [openTooltip]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText('https://app.ugcc.xyz/' + _walletAddress);
    setOpenTooltip(true);
  }

  const MobileFooter = () => {
    const [value, setValue] = React.useState('');
    const handleChange = (event, newVal) => {
      setValue(newVal);
      if (newVal === 'share') {
        copyToClipboard();
      } else if (newVal === 'public') {
        navigate('/' + _walletAddress);
      } else if (newVal === 'edit') {
        //navigate('/'+_walletAddress);
      }
    }

    return (
      <BottomNavigation sx={{ width: 500 }} value={value} onChange={handleChange}>
        <BottomNavigationAction
          label="Edit"
          value="edit"
          icon={<CreateProfile clickType='icon' Userfront={Userfront} createOrUpdate={'update'} onComplete={refreshPage}></CreateProfile>}
        />
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          open={openTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Copied to Clipboard"
        >
          <BottomNavigationAction
            label="Share"
            value="share"
            icon={<ShareIcon />}
          />
        </Tooltip>
        <BottomNavigationAction
          label="Public Profile"
          value="public"
          icon={<LanguageIcon />}
        />
      </BottomNavigation>
    )
  }

  return (
    <Box>
      {!isLoggedIn &&
        <Grid
          container={true}
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '90vh' }}>
          {/*<IconButton aria-label="addRecord" onClick={newChannel}>
            <AddIcon />
          </IconButton>*/}
          <Grid item xs={3}>
            <Button component={Link} to={'/login'} variant="contained" color={theme.palette.mode === 'light' ? 'secondary' : 'primary'}>Login</Button>
          </Grid>
        </Grid>
      }

      {isLoggedIn && userProfile.walletAddress === null &&
        <Grid
          container={true}
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '90vh' }}>
          {/*<IconButton aria-label="addRecord" onClick={newChannel}>
            <AddIcon />
          </IconButton>*/}
          <Grid item xs={3}>
            <CreateProfile clickType='button' Userfront={Userfront} createOrUpdate={'create'} onComplete={refreshPage}></CreateProfile>
          </Grid>
        </Grid>
      }
      {isLoggedIn && userProfile.creatorName !== null &&
        <>
          <Box>
            <Card elevation={0} sx={{ borderWidth: 0, width: '90%', mx: 'auto', boxShadow: 'none', mt: 10, backgroundColor: theme.palette.mode === 'dark' ? '#282c34' : '#fff' }}>

              <CardContent sx={{ textAlign: 'center' }}>
                {!isMobile &&
                  <Stack direction={'row'}>
                    <Chip icon={<ConnectWithoutContactIcon />} label={"Connect with Brands"} variant="filled" clickable={true} sx={{ mx: 4, mt: 2, mb: 4, fontSize: 12 }} />
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      open={openTooltip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="Copied to Clipboard"
                    >
                      <Chip icon={<ShareIcon />} label={"Share Portfolio"} onClick={copyToClipboard} variant="outlined" clickable={true} sx={{ mx: 4, mt: 2, mb: 4, fontSize: 12 }} />
                    </Tooltip>
                    <Chip icon={<LanguageIcon />} label={"Public Portfolio"} component={Link} to={'/' + _walletAddress} variant="outlined" clickable={true} sx={{ mx: 4, mt: 2, mb: 4, fontSize: 12 }} />
                    <CreateProfile clickType='chip' Userfront={Userfront} createOrUpdate={'update'} onComplete={refreshPage}></CreateProfile>
                  </Stack>
                }
                <Avatar src={userProfile.image} variant='circular' sx={{ width: 100, height: 100, mx: 'auto' }} />
                <Typography variant="h5" sx={{ my: 3 }}>{userProfile.creatorName}</Typography>
                <Typography variant="body2" sx={{ my: 3 }}>{userProfile.intro}</Typography>
              </CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <SocialIcons
                  twitterHandle={userProfile.twitterHandle}
                  email={userProfile.email}
                  websiteUrl={userProfile.website}
                  youtubeHandle={userProfile.youtubeHandle}
                  instagramHandle={userProfile.instagramHandle}
                  tiktokHandle={userProfile.tiktokHandle}
                ></SocialIcons>
              </Box>
              <ProfileTabs ></ProfileTabs>
              {tabIndex === 0 &&
                <Grid
                  container
                  direction="row"
                  sx={{ m: 10 }}
                >
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>UGC Types</Typography>
                    <List>
                      {
                        formatListMapping(userProfile.ugcTypes, 'ugcTypes').map((val) => {
                          return (
                            <ListItem key={val} dense={true}>
                              <ListItemText sx={{ fontSize: 40 }}>{val}</ListItemText>
                            </ListItem>
                          )
                        })
                      }
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Industries</Typography>
                    <List>
                      {
                        formatListMapping(userProfile.industries, 'industries').map((val) => {
                          return (
                            <ListItem key={val} dense={true}>
                              <ListItemText>{val}</ListItemText>
                            </ListItem>
                          )
                        })
                      }
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Specialties</Typography>
                    <List>
                      {
                        formatListMapping(userProfile.specialties, 'specialties').map((val) => {
                          return (
                            <ListItem key={val} dense={true}>
                              <ListItemText>{val}</ListItemText>
                            </ListItem>
                          )
                        })
                      }
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Platforms</Typography>
                    <List>
                      {
                        formatListMapping(userProfile.platform, 'platform').map((val) => {
                          return (
                            <ListItem key={val} dense={true}>
                              <ListItemText>{val}</ListItemText>
                            </ListItem>
                          )
                        })
                      }
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Process</Typography>
                    <List>
                      <ListItem dense={true}><ListItemText>{userProfile.process}</ListItemText></ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Pricing</Typography>
                    <List>
                      <ListItem dense={true}><ListItemText>{userProfile.pricing}</ListItemText></ListItem>
                    </List>
                  </Grid>
                </Grid>
              }
              {tabIndex === 1 &&
                <Grid
                  container
                  direction="row"
                  sx={{ m: 10 }}
                >
                  <Grid item xs={12}>
                    <CreatePost walletAddress={userProfile.walletAddress} clickType={'button'} refreshList={refreshList}></CreatePost>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={10}>
                      {portfolioList && Array.isArray(portfolioList) &&
                        portfolioList.map((val, index) => {
                          return (
                            <div key={index}>
                              <FeedItem portfolioItem={val} maxWidth={500} Userfront={Userfront} refreshList={refreshList}></FeedItem>
                            </div>
                          )
                        })
                      }
                    </Stack>

                  </Grid>
                </Grid>
              }

              {isMobile &&
                <MobileFooter></MobileFooter>
              }
            </Card>
          </Box>
        </>

      }
    </Box>
  )
}