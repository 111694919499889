import React from 'react';
import Box from '@mui/material/Box';

const ArchImage = ({ image, name }) => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        borderRadius: '47% 47% 0 0', // Rounded top corners
        width: 'fit-content', // Adjust the width as needed
      }}
    >
      <img src={image} alt={name} style={{ width: '100%', display: 'block' }} />
    </Box>
  );
};

export default ArchImage;

