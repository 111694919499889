import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import FormHelperText from '@mui/material/FormHelperText';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import DialogContent from '@mui/material/DialogContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {Grid, DialogTitle, OutlinedInput } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//import { basicSvg } from '../../../../static/constants';
import LoadingButton from '@mui/lab/LoadingButton';
import { urlCheck, getProfile, saveProfile, ugcTypeMapping, industryMapping, specialtiesMapping, platformMapping, processMapping } from '../static/utility';

// Custom Components
import UploadImage from './UploadImage';
import TextInput from './TextInput';


//mintChannel
//const svgString = basicSvg.svgPartOne + basicSvg.svgPartTwo + basicSvg.svgPartThree
const steps = ['Basics', 'Content Details', 'Contact Details'];

function CreateProfile({clickType, Userfront, createOrUpdate, onComplete}) {
  const navigate = useNavigate();
  const theme = useTheme(); 
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [validInputs, setValidInputs] = useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleCloseError = () => {
    setErrorMessage('');
    setOpenError(false);
  };
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const [creatorName, setName] = useState('');
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const [intro, setIntro] = useState('');
  const handleIntroChange = (event) => {
    setIntro(event.target.value);
  };

  
  const [ugcTypes, setUgcTypes] = useState([]);
  const handleUgcTypesChange = (event) => {
    setUgcTypes(event.target.value);
  };
  
  const [industries, setIndustries] = useState([]);
  const handleIndustriesChange = (event) => {
    setIndustries(event.target.value);
  };

  const [specialties, setSpecialties] = useState([]);
  const handleSpecialtiesChange = (event) => {
    setSpecialties(event.target.value);
  };

  const [platform, setPlatform] = useState([]);
  const handlePlatformChange = (event) => {
    setPlatform(event.target.value);
  };

  const [process, setProcess] = useState([]);
  const handleProcessChange = (event) => {
    setProcess(event.target.value);
  };

  const [pricing, setPricing] = useState('');
  const handlePricingChange = (event) => {
    setPricing(event.target.value);
  };
  const [email, setEmail] = useState('');
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const [phone, setPhone] = useState('');
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const [tiktokHandle, setTiktokHandle] = useState('');
  const handleTiktokChange = (event) => {
    setTiktokHandle(event.target.value);
  };

  const [youtubeHandle, setYoutubeHandle] = useState('');
  const handleYoutubeChange = (event) => {
    setYoutubeHandle(event.target.value);
  };

  const [instagramHandle, setInstagramHandle] = useState('');
  const handleInstagramChange = (event) => {
    setInstagramHandle(event.target.value);
  };

  const [twitterHandle, setTwitterHandle] = useState('');
  const handleTwitterChange = (event) => {
    setTwitterHandle(event.target.value);
  };

  const [website, setWebsite] = useState('');
  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
    if (event.target.value.length > 0) {
      setValidUrl(urlCheck(event.target.value));
    } else {
      setValidUrl(true);
    }
  };
  const [validUrl, setValidUrl] = useState(true);

  const [image, setImage] = useState('');

  const [, setMetadata] = useState({});

  const [walletAddress, setWalletAddress] = useState('');
  useEffect(() => {
    const runAsync = async () => {
      const profileResponse = await getProfile(Userfront.user.userUuid);
      if (profileResponse) {
        //console.log(profileResponse);
        setName(profileResponse.creatorName || '');
        setIntro(profileResponse.intro || '');
        setUgcTypes(profileResponse.ugcTypes || []);
        setIndustries(profileResponse.industries || []);
        setSpecialties(profileResponse.specialties || []);
        setPlatform(profileResponse.platform || []);
        setProcess(profileResponse.process || []);
        setPricing(profileResponse.pricing || '');
        setEmail(profileResponse.email || '');
        setPhone(profileResponse.phone || '');
        setTiktokHandle(profileResponse.tiktokHandle || '');
        setYoutubeHandle(profileResponse.youtubeHandle || '');
        setInstagramHandle(profileResponse.instagramHandle || '');
        setTwitterHandle(profileResponse.twitterHandle || '');
        setWebsite(profileResponse.website || '');
        setImage(profileResponse.image);
      }
      
    }
    
    if (Userfront.user.userUuid){
      setWalletAddress(Userfront.user.userUuid);
      runAsync();
    }
  }, [Userfront])

  /**/
  const upsertProfile = async function() {
    try {
      setSubmissionLoading(true);

      if (validInputs) {
        const profile = {
          chainId: 1,
          walletAddress,
          creatorName : creatorName,
          intro: intro,
          ugcTypes,
          industries,
          specialties,
          platform,
          process,
          pricing,
          email,
          phone,
          tiktokHandle,
          youtubeHandle,
          instagramHandle,
          twitterHandle,
          website,
          image
        };
        
        const results = await saveProfile(walletAddress, profile);
        setSubmissionLoading(false);
        setCreateModalOpen(false);
        onComplete();
      } else {
        setSubmissionLoading(false);
      }
    } catch (err) {
      console.log(err);
      setSubmissionLoading(false);
    }
    
    
  }

  function mainImageChanged(event) {
    setImage(event);
  }

  function newModal() {
    setCreateModalOpen(true);
  }

  function closeNewModal() {
    setActiveStep(0);
    setCreateModalOpen(false);
  }

  // Stepper Functions
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const errorAction = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={()=>{handleCloseError()}}>
        
      </Button>
      <IconButton
        size="small"
        color="inherit"
        onClick={handleCloseError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  
  useEffect(() => {
    if ( 
      creatorName !== '' && 
      intro !== '' && 
      ugcTypes.length > 0 && 
      industries.length > 0 &&
      specialties.length > 0 &&
      platform.length > 0 &&
      process.length > 0 &&
      pricing !== '' &&
      email !== '' 
    ) {
      setValidInputs(true);
      
    } else {setValidInputs(false);}
    
  },[creatorName,website,validUrl,image, intro, ugcTypes, industries, specialties, platform, process, pricing, email, phone, tiktokHandle, youtubeHandle, instagramHandle, twitterHandle, createModalOpen]);
  
  return (
    <>
      {clickType === 'button' && 
        <Grid 
          container={true}
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '90vh' }}>
          {/*<IconButton aria-label="addRecord" onClick={newChannel}>
            <AddIcon />
          </IconButton>*/}
          <Grid item xs={3}>
            <Button variant="contained" color={theme.palette.mode === 'light' ? 'secondary' : 'primary'} onClick={newModal} sx={{mx:'auto', display: 'block'}}>{createOrUpdate === 'create' ? "Create Profile" : "Update Profile"} </Button>
          </Grid>
          
        </Grid>
      }
      {clickType === 'chip' && 
        <Chip icon={<EditIcon />} label={createOrUpdate === 'create' ? "Create Profile" : "Edit"} onClick={newModal}  variant="outlined" clickable={true} sx={{ mx: 4, mt:2,mb:4,fontSize:12}}/>
      }   
      {clickType === 'icon' && 
        <IconButton onClick={newModal}><EditIcon /></IconButton>
      }
      <Dialog open={createModalOpen} onClose={closeNewModal} maxWidth={'sm'}>
        <DialogTitle>
          Create Profile 
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeNewModal}
            sx={{float:'right'}}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Card sx={{ }} className="transparent-background">
            
              {activeStep === 0 && 
                <div>
                  <Grid 
                    container={true}
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '10vh' }}>
                    {/*<IconButton aria-label="addRecord" onClick={newChannel}>
                      <AddIcon />
                    </IconButton>*/}
                    <Grid item xs={3} mt={10}>
                      <UploadImage onImageChange={mainImageChanged} existingImage={image}></UploadImage>
                    </Grid>
                  </Grid>
                  
                  <Box sx={{mb:2}}>
                    <TextField
                      id="name"
                      variant="standard"
                      label="Name"
                      placeholder='Name'
                      fullWidth
                      value={creatorName}
                      onChange={handleNameChange}
                      sx={{display: 'block', mx: "auto", width:500}}
                    />
                    <TextField
                      id="intro"
                      label="Intro"
                      variant="standard"
                      fullWidth
                      multiline
                      rows={4}
                      value={intro}
                      placeholder="I'm a ___ (descriptive noun such as creator, photographer, makeup fan) , based in ___ (location) with ____ (relevant experience, credentials, degrees, etc)."
                      onChange={handleIntroChange}
                      sx={{display: 'block', mx: "auto", width:500, mb:4}}/>
                    <FormHelperText sx={{color:'black'}}>Deal Types</FormHelperText>
                    <Select
                      id="process"
                      fullWidth
                      multiple
                      variant="standard"
                      value={process}
                      label="Deal Types"                   
                      onChange={handleProcessChange}
                      input={<OutlinedInput id="select-multiple-chip" label="UGC Types" />}
                      renderValue={(selected) => {
                        return (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={processMapping[value]} />
                            ))}
                          </Box>
                        )
                      }}
            
                      sx={{display: 'block', mx: "auto", maxWidth:500, mt:2}}
                    >
                      {
                        Object.keys(processMapping).map((oKey) => (
                          <MenuItem value={oKey} key={oKey}>{processMapping[oKey]}</MenuItem>
                        ))
                      }
                    </Select>
                    <FormHelperText >Select all that apply</FormHelperText>
                    
                    <TextField
                      id="pricing"
                      variant="standard"
                      label="Pricing"
                      placeholder='Contact me for rates'
                      fullWidth
                      value={pricing}
                      onChange={handlePricingChange}
                      sx={{display: 'block', mx: "auto", maxWidth:500, mt: 5}}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <Button onClick={handleNext}>
                      Next
                    </Button>
                  </Box>
                </div>
              }

              {activeStep === 1 && 
                <Box sx={{alignItems: 'flex-end'}}>
                  <Box maxWidth="max" sx={{ flexDirection: 'row', p: 8}}> 
                  {
                    <Box sx={{ justifyContent: 'center'}}>                      
                      <FormHelperText sx={{color:'black'}}>UGC Types</FormHelperText>
                      <Select
                        id="ugcTypes"
                        fullWidth
                        multiple
                        variant="standard"
                        value={ugcTypes}
                        label="UGC Types"                   
                        onChange={handleUgcTypesChange}
                        input={<OutlinedInput id="select-multiple-chip" label="UGC Types" />}
                        renderValue={(selected) => {
                          return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => (
                                <Chip key={value} label={ugcTypeMapping[value]} />
                              ))}
                            </Box>
                          )
                        }}
              
                        sx={{display: 'block', mx: "auto", maxWidth:500, mt:2}}
                      >
                        {
                          Object.keys(ugcTypeMapping).map((oKey) => (
                            <MenuItem value={oKey} key={oKey}>{ugcTypeMapping[oKey]}</MenuItem>
                          ))
                        }
                      </Select>
                      <FormHelperText>Select all that apply</FormHelperText>
                                  
                      <FormHelperText sx={{color:'black'}}>Industries</FormHelperText>
                      <Select
                        id="industry"
                        fullWidth
                        multiple
                        variant="standard"
                        value={industries}
                        label="Industries"                   
                        onChange={handleIndustriesChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => {
                          return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => (
                                <Chip key={value} label={industryMapping[value]} />
                              ))}
                            </Box>
                          )
                        }}
              
                        sx={{display: 'block', mx: "auto", maxWidth:500, mt:2}}
                      >
                        {
                          Object.keys(industryMapping).map((oKey) => (
                            <MenuItem value={oKey} key={oKey}>{industryMapping[oKey]}</MenuItem>
                          ))
                        }
                      </Select>
                      <FormHelperText>Select all that apply</FormHelperText>
                      
                      <FormHelperText sx={{color:'black'}}>Specialties</FormHelperText>
                      <Select
                        id="specialties"
                        fullWidth
                        multiple
                        variant="standard"
                        value={specialties}
                        label="Specialties"                   
                        onChange={handleSpecialtiesChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => {
                          return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => (
                                <Chip key={value} label={specialtiesMapping[value]} />
                              ))}
                            </Box>
                          )
                        }}
              
                        sx={{display: 'block', mx: "auto", maxWidth:500, mt:2}}
                      >
                        {
                          Object.keys(specialtiesMapping).map((oKey) => (
                            <MenuItem value={oKey} key={oKey}>{specialtiesMapping[oKey]}</MenuItem>
                          ))
                        }
                      </Select>
                      <FormHelperText>Select all that apply</FormHelperText>

                      <FormHelperText sx={{color:'black'}}>Platforms</FormHelperText>
                      <Select
                        id="platform"
                        fullWidth
                        multiple
                        variant="standard"
                        value={platform}
                        label="Platforms"                   
                        onChange={handlePlatformChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => {
                          return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => (
                                <Chip key={value} label={platformMapping[value]} />
                              ))}
                            </Box>
                          )
                        }}
              
                        sx={{display: 'block', mx: "auto", maxWidth:500, mt:2}}
                      >
                        {
                          Object.keys(platformMapping).map((oKey) => (
                            <MenuItem value={oKey} key={oKey}>{platformMapping[oKey]}</MenuItem>
                          ))
                        }
                      </Select>
                      <FormHelperText>Select all that apply</FormHelperText>
                    </Box>
                  }
                    
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <Button onClick={handleNext}>
                      Next
                    </Button>
                  </Box>
                </Box>
              }
              {activeStep === 2 && 
                <Box sx={{alignItems: 'flex-end'}}>
                  <Box maxWidth="max" sx={{ flexDirection: 'row', p: 8}}> 
                  {
                    <Box sx={{ justifyContent: 'center'}}>
                      <TextField
                        id="email"
                        variant="standard"
                        label="Email"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        sx={{display: 'block', mx: "auto", maxWidth:500, mb:4}}
                      />
                      <TextField
                        id="phone"
                        variant="standard"
                        label="Phone"
                        fullWidth
                        value={phone}
                        onChange={handlePhoneChange}
                        sx={{display: 'block', mx: "auto", maxWidth:500, mb:4}}
                      />
                      <TextField
                        id="tiktokHandle"
                        variant="standard"
                        label="TikTok Handle"
                        fullWidth
                        value={tiktokHandle}
                        onChange={handleTiktokChange}
                        sx={{display: 'block', mx: "auto", maxWidth:500, mb:4}}
                      />
                      <TextField
                        id="youtubeHandle"
                        variant="standard"
                        label="Youtube Handle"
                        fullWidth
                        value={youtubeHandle}
                        onChange={handleYoutubeChange}
                        sx={{display: 'block', mx: "auto", maxWidth:500, mb:4}}
                      />
                      <TextField
                        id="instagramHandle"
                        variant="standard"
                        label="Instagram Handle"
                        fullWidth
                        value={instagramHandle}
                        onChange={handleInstagramChange}
                        sx={{display: 'block', mx: "auto", maxWidth:500, mb:4}}
                      />
                      <TextField
                        id="twitterHandle"
                        variant="standard"
                        label="Twitter Handle"
                        fullWidth
                        value={twitterHandle}
                        onChange={handleTwitterChange}
                        sx={{display: 'block', mx: "auto", maxWidth:500, mb:4}}
                      />
                      <TextField
                        id="website"
                        variant="standard"
                        label="Website"
                        fullWidth
                        value={website}
                        onChange={handleWebsiteChange}
                        sx={{display: 'block', mx: "auto", maxWidth:500, mb:4}}
                      />    
                    </Box>
                  }
                    
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <LoadingButton
                      component="span"
                      loading={submissionLoading}
                      disabled={!validInputs}
                      variant="contained"
                      onClick={upsertProfile}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </Box>
              }
          </Card>
        </DialogContent>
      </Dialog>
      
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
        message={errorMessage}
        action={errorAction}
      />
      
    </>
  );
}

export default CreateProfile;


