import { env } from './constants';
import axios from 'axios';
import { List, ListItem, ListItemText } from '@mui/material';


export const urlCheck = (websiteUrl) => { 
  const urlRegEx = new RegExp(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/);
    return urlRegEx.test(String(websiteUrl).toLowerCase());
}

export const getProfile = async (walletAddress) => {
  try {
    if (walletAddress !== '') {  
      const _userProfile = await axios.get(env.REACT_APP_LAMBDA_URL+'profile?walletAddress='+walletAddress);
      //console.log(_userProfile);
      return _userProfile['data']['Items'][0];
    } else {
      return null;
    }
  } catch (err) {
    console.log('error getting profile', err);
    return null;
  }
  
}

export const saveProfile = async (walletAddress, profile) => {
  if (walletAddress !== '') {  
    var config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const _userProfile = await axios.post(env.REACT_APP_LAMBDA_URL+'profile?walletAddress='+walletAddress, profile, config);
    return _userProfile;
  } else {
    return null;
  }
}

export const getPortfolio = async (walletAddress) => {
  try {
    if (walletAddress !== '') {  
      const _userPortfolio = await axios.get(env.REACT_APP_LAMBDA_URL+'portfolio?walletAddress='+walletAddress);
      //console.log(_userProfile);
      return _userPortfolio['data']['Items'];
    } else {
      return null;
    }
  } catch (err) {
    console.log('error getting portfolio', err);
    return null;
  }
  
}

export const savePortfolioItem = async (walletAddress, portfolioItem) => {
  if (walletAddress !== '') {  
    var config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const _userProfile = await axios.post(env.REACT_APP_LAMBDA_URL+'portfolio?walletAddress='+walletAddress, portfolioItem, config);
    return _userProfile;
  } else {
    return null;
  }
}

export const deletePortfolioItem = async (walletAddress, entryId) => {
  if (walletAddress !== '' && entryId !== '') {  
    var config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const _userProfile = await axios.delete(env.REACT_APP_LAMBDA_URL+'portfolio?walletAddress='+walletAddress+'&entryId='+entryId, config);
    return _userProfile;
  } else {
    return null;
  }
}

export const ugcTypeMapping = { 
  'asmr':'ASMR Videos',
  'drawing_v':'Drawing Videos',
  'green_screen': 'Green Screen',
  'modeling_p':'Modeling Products',
  'on_camera':'On-camera Appearance',
  'tutorial_p': 'Product Tutorial',
  'photography_p':'Product Photography',
  'reviews':'Reviews',
  'storytime': 'Storytime',
  'talking_head': 'Talking Head',
  'text_over_p': 'Text-Over-Product',
  'unboxing_v': 'Unboxing Videos',
  'writing_v':'Writing Videos',
  'other' : 'Other'
};

export const industryMapping = {
  'beauty':'Beauty',
  'cpg':'Consumer Packaged Goods',
  'entertainment' : 'Entertainment',
  'fashion':'Fashion',
  'fitness':'Fitness',
  'gaming':'Gaming',
  'lifestyle' : 'Lifestyle',
  'music' : 'Music',
  'mj' : 'Marijuana',
  'real_estate' : 'Real Estate',
  'tech':'Technology',
  'travel' : 'Travel',
  'other' : 'Other'
};

export const specialtiesMapping =  {
  'drone_p' : 'Drone Photography',
  'dslr_p':'DSLR Photography',
  'iphone_p':'iPhone Photography',
  'product_p':'Product Photography',
  'sf_v':'Short-form Video',
  'slow_motion' : 'Slow Motion',
  'stop_motion': 'Stop Motion',
  'trending_c' : 'Trending Content',
  'video':'Videography',
  'voiceover':'Voiceover',
  'other' : 'Other',
};

export const platformMapping = {
  'ig':'Instagram',
  'snap':'Snapchat',
  'tiktok':'Tiktok',
  'ytube':'YouTube',
  'other' : 'Other',
};

export const processMapping = {
  'single': 'Single Video',
  'bundle': 'Packages/Bundles',
  'other' : 'Other',
};

export const formatListMapping = (inputList, type) => {
  if (inputList && Array.isArray(inputList)){
    if (type === 'ugcTypes') {
      return inputList.map((val) => {
        return ugcTypeMapping[val];      
      })
    } else if (type === 'industries') {
      return inputList.map((val) => {
        return industryMapping[val];      
      })
    } else if (type === 'specialties') {
      return inputList.map((val) => {
        return specialtiesMapping[val];      
      })
    } else if (type === 'platform') {
      return inputList.map((val) => {
        return platformMapping[val];      
      })
    } else if (type === 'process') {
      return inputList.map((val) => {
        return processMapping[val];      
      })
    } else {
      return inputList;
    }
  } else {
    return  [];
  }
  
}