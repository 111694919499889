import React from "react";
import { Box, Grid } from "@mui/material";
import { env } from "../../static/constants";
import Userfront from "@userfront/react";




export default function SignupPage({Userfront}) {
  const SignupForm = Userfront.build({
    toolId: "nmlkoa"
  });
  return (
    <Grid 
        container={true}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', backgroundColor: '#071F4E' }}>
        {/*<IconButton aria-label="addRecord" onClick={newChannel}>
          <AddIcon />
        </IconButton>*/}
        <Grid item xs={3}>
          <SignupForm />
        </Grid>
    </Grid>
  )
    
}