import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArchedIcon = ({ color }) => {
  return (
    <SvgIcon
      viewBox="0 0 50 50" // Adjusted viewBox for 50x50 dimensions
      sx={{
        width: 50, // Set the external width of the SVG to 50
        height: 50, // Set the external height of the SVG to 50
        // Additional styling if needed
      }}
    >
      <path
        d="M25 2C12.85 2 2 12.85 2 25h46c0-12.15-10.85-23-23-23zM2 25v23h46V25" // Adjusted path for the 50x50 viewBox
        fill={color} // Fill color for the icon
      />
    </SvgIcon>
  );
};

export default ArchedIcon;
