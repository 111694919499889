import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Card, CardActions, CardMedia, CardContent, Chip, Divider, Grid, List, ListItem, ListItemText, Stack, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import ThemeButton from '../ThemeButton';
import LogoDark from '../assets/images/logo.png';
import SocialIcons from '../ui/SocialIcons';
import { ugcTypeMapping, industryMapping, specialtiesMapping, platformMapping, formatListMapping } from '../../static/utility';
import ArchImage from '../ui/ArchImage';
import CreatorFooter from './CreatorFooter';


export default function CreatorBanner({ userProfile }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  useEffect(() => {
    if (userProfile.email) {
      setEmail('mailto:' + userProfile.email);
    }
  }, [userProfile])

  return (
    <Grid container sx={{ borderWidth: 1, borderStyle: 'solid', borderColor: 'transparent', borderRadius: '0.25rem', mt: 25, }}>
      {isMobile &&
        <Grid item md={0} xs={12}>
          <ArchImage image={userProfile.image} name={userProfile.creatorName} />
        </Grid>
      }

      <Grid item md={7} xs={12}>
        <Stack alignItems={'start'} justifyContent={'start'} direction="column" height={'100%'}>
          <Stack spacing={10} sx={{ textAlign: 'start' }} my={16}>
            <Typography variant="h3" fontWeight={600}>{userProfile.creatorName}</Typography>
            <Typography variant="body2" lineHeight={1.7}>{userProfile.intro}</Typography>
          </Stack>
          <Button href={email} variant={'contained'} color={'secondary'} sx={{ paddingX: 18, paddingY: 6, borderRadius: 0, textTransform: 'none', fontWeight: 600 }}>Contact</Button>

          <CreatorFooter userProfile={userProfile} />
        </Stack>
      </Grid>
      <Grid item md={1} xs={0}></Grid>
      {!isMobile &&
        <Grid item md={4} xs={0}>
          <ArchImage image={userProfile.image} name={userProfile.creatorName} />
        </Grid>
      }
    </Grid>

  );
}