import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import PublicIcon from '@mui/icons-material/Public';
import InstagramIcon from '@mui/icons-material/Instagram';
import GavelIcon from '@mui/icons-material/Gavel';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useTheme } from '@mui/material/styles';

const TikTokIcon = ({ color }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="24"
      height="24"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

function SocialIcons (inputs) {
  
  const theme = useTheme(); 
  return (
    <>      
      <ButtonGroup variant="text" aria-label="text button group" sx={{mx:'auto'}}>
        { inputs.email && inputs.email !== '' && 
          <IconButton aria-label="email" href={'mailto:'+inputs?.email} target="_blank">
            <EmailIcon />
          </IconButton>
        }
        { inputs.twitterHandle && inputs.twitterHandle !== '' && 
          <IconButton aria-label="twitter" href={inputs?.twitterHandle} target="_blank">
            <TwitterIcon />
          </IconButton>
        }
        { inputs.githubUrl && inputs.githubUrl !== '' && 
          <IconButton aria-label="github" href={inputs?.githubUrl} target="_blank">
            <GitHubIcon />
          </IconButton>
        }        
        { inputs.instagramHandle && inputs.instagramHandle !== '' && 
          <IconButton aria-label="instagram" href={inputs?.instagramHandle} target="_blank">
            <InstagramIcon />
          </IconButton>
        }       
        { inputs.tiktokHandle && inputs.tiktokHandle !== '' && 
          <IconButton aria-label="instagram" href={inputs?.tiktokHandle} target="_blank">
            <TikTokIcon color={theme.palette.mode === 'light' ? "rgba(0, 0, 0, 0.54)" : '#fff'}/>
          </IconButton>
        }      
        { inputs.youtubeHandle && inputs.youtubeHandle !== '' && 
          <IconButton aria-label="instagram" href={inputs?.youtubeHandle} target="_blank">
            <YouTubeIcon />
          </IconButton>
        }
        { inputs.governanceUrl && inputs.governanceUrl !== '' && 
          <IconButton aria-label="governance" href={inputs?.governanceUrl} target="_blank">
            <GavelIcon />
          </IconButton>
        }
        { inputs.websiteUrl && inputs.websiteUrl !== '' && 
          <IconButton aria-label="website" href={inputs?.websiteUrl} target="_blank">
            <PublicIcon />
          </IconButton>
        }
      </ButtonGroup>
    </>
    
  )
}

export default SocialIcons;