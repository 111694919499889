/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, CardMedia, CardContent, Typography, CardActions, Button, Skeleton, Box, IconButton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import ReactPlayer from 'react-player';
import { TikTok } from 'react-tiktok';
import ErrorBoundary from '../ErrorBoundary';
//import InstagramEmbed from 'react-instagram-embed';
import { InstagramEmbed } from 'react-social-media-embed';
import { useTheme } from '@mui/material/styles';
import { ugcTypeMapping, deletePortfolioItem } from '../../static/utility';
import { env } from '../../static/constants';
import EmptySpace from '../ui/EmptySpace';

function FeedItem({ portfolioItem, maxWidth, Userfront, refreshList }) {

  const theme = useTheme();
  const [igUrl, setIgUrl] = useState('');
  useEffect(() => {
    if (Userfront) {
      console.log(Userfront.user.userUuid, portfolioItem);
    }
  }, [Userfront]);
  useEffect(() => {
    console.log(portfolioItem);
    if (portfolioItem.postUrl.includes('instagram.com') || portfolioItem.postUrl.includes('instagr.am') && portfolioItem.postUrl.includes('https://www.instagram.com/reel/CiciKNGrPV1/?utm_source=ig_web_copy_link')) {
      setIgUrl('https://www.instagram.com/reel/CiciKNGrPV1/?utm_source=ig_embed&amp;utm_campaign=loading');
    }

  }, [portfolioItem])

  const deleteItem = async () => {
    const results = await deletePortfolioItem(Userfront.user.userUuid, portfolioItem.entryId);
    refreshList();
  }

  const RenderTiktok = () => {
    try {
      return <TikTok url={portfolioItem.postUrl} />
    } catch (err) {
      console.log(err);
      return <>Error Loading Item</>
    }
  }
  return (
    <>
      {portfolioItem &&
        <Card sx={{ maxWidth: maxWidth, mx: 'auto', backgroundColor: '#ffffff', borderColor: 'transparent', boxShadow: 'none' }}>
          {/*
            <CardMedia
              component='iframe'
              height="140"
              image={portfolioItem.postUrl}
            />
          */}

          <CardContent>
            <Grid2
              container
              direction="row"
            >
              <Grid2 xs={12}>
                <Typography gutterBottom variant="body1" component="div" color="text" sx={{ mb: 6, mt: 0, textAlign: 'center' }}>
                  {ugcTypeMapping[portfolioItem.ugcType] || <EmptySpace />}
                </Typography>
              </Grid2>

              <Grid2 smOffset="auto">
                {Userfront && Userfront.user.userUuid === portfolioItem.walletAddress &&
                  <IconButton color={theme.palette.mode === 'light' ? 'secondary' : 'primary'} onClick={deleteItem}>
                    <DeleteIcon />
                  </IconButton>
                }
              </Grid2>
            </Grid2>

            {(portfolioItem.postUrl.includes('instagram.com') || portfolioItem.postUrl.includes('instagr.am')) &&
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ErrorBoundary>
                  <InstagramEmbed url={portfolioItem.postUrl} width={328} />
                </ErrorBoundary>
              </div>
            }

            {portfolioItem.postUrl.includes('tiktok.com') &&
              <ErrorBoundary>
                <TikTok url={portfolioItem.postUrl} />
              </ErrorBoundary>
            }

            {!portfolioItem.postUrl.includes('tiktok.com') && !portfolioItem.postUrl.includes('instagram.com') && !portfolioItem.postUrl.includes('instagr.am') &&
              <ErrorBoundary>
                <ReactPlayer url={portfolioItem.postUrl} width={maxWidth - 25} />
              </ErrorBoundary>
            }


          </CardContent>
        </Card>
      }
      {!portfolioItem &&
        <Skeleton variant="rectangular" width={210} height={118} />
      }

    </>

  )
}

export default FeedItem;

/*

<InstagramEmbed
  url={portfolioItem.postUrl}
  // To add current client access token
  clientAccessToken={env.REACT_APP_FACEBOOK_APP_ID+env.REACT_APP_FACEBOOK_SECRET}
  maxWidth={maxWidth-25}
  hideCaption={false}
  containerTagName='div'
  protocol=''
  injectScript
/>
*/