import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { env } from "../../static/constants";



export default function LoginPage({Userfront}) {
  const LoginForm = Userfront.build({
    toolId: "lrnlko"
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const [username, setUserName] = useState('');
  const handleUsernameChange = (event) => {
    setUserName(event.target.value);
  };
  const [password, setPassword] = useState('');
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  
  const [errorMessage, setErrorMessage] = useState('');

  const loginUser = () => {
    if (username !== '' && password !== '') {
      setErrorMessage('');
      // Call Userfront.login()
      Userfront.login({
        method: "password",
        emailOrUsername: username,
        password: password
      }).then((result) => {
        //navigate('/dashboard')
      }).catch((error) => {
        //setErrorMessage(error.message);
      });
  
    }
  }

  const CustomLogin = () => {
    return (
      <Card key="customlogin">
        <CardContent>
          
          <TextField
            id="username123"
            variant="standard"
            label="Username"
            fullWidth
            value={username}
            onChange={handleUsernameChange}
            sx={{mb:10}}
          />
          <TextField
            id="password123"
            variant="standard"
            label="Password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
          />
          {errorMessage !== '' && 
            <Typography variant="body2" sx={{color: 'red'}}></Typography>
          }
          <Button onClick={loginUser} variant="contained" fullWidth={true} color={theme.palette.mode === 'light' ? 'secondary' : 'primary'} sx={{color: '#fff', mt:10}}>Login</Button>
          
        </CardContent>
      </Card>
    )
  };
  return (
    <Grid 
        container={true}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', backgroundColor: '#071F4E' }}>
        
        <Grid item xs={12}>
          <LoginForm style={{backgroundColor: '#071F4E'}} />
        </Grid>
    </Grid>
  )
    
}
