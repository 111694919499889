import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import Chip from '@mui/material/Chip';
import DialogContent from '@mui/material/DialogContent';
import AddIcon from '@mui/icons-material/Add';
import {Grid, DialogTitle, OutlinedInput, Select, MenuItem, FormHelperText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';

//import { basicSvg } from '../../../../static/constants';
import LoadingButton from '@mui/lab/LoadingButton';
import { urlCheck, savePortfolioItem, ugcTypeMapping, industryMapping, specialtiesMapping, platformMapping} from '../static/utility';

// Custom Components


function CreatePost({walletAddress, clickType, refreshList}) {
  const theme = useTheme(); 
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [validInputs, setValidInputs] = useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleCloseError = () => {
    setErrorMessage('');
    setOpenError(false);
  };
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  
  const [validUrl, setValidUrl] = useState(true);

  const [postUrl, setPostUrl] = useState('');
  const handlePostUrlChange = (event) => {
    setPostUrl(event.target.value);
    if (event.target.value.length > 0) {
      setValidUrl(urlCheck(event.target.value));
    } else {
      setValidUrl(true);
    }
  };

  const [ugcType, setUgcType] = useState('');
  const handleUgcTypeChange = (event) => {
    setUgcType(event.target.value);
  };

  /**/
  const upsertPortfolioItem = async function() {
    try {
      setSubmissionLoading(true);
      
      if (validInputs) {    
        const portfolioItem = {
          walletAddress,
          entryId: uuidv4(),
          postUrl, 
          ugcType
        };
        const results = await savePortfolioItem(walletAddress, portfolioItem);
        console.log(results);
        setSubmissionLoading(false);
        setCreateModalOpen(false);
        setPostUrl('');
        refreshList();
      } else {
        setSubmissionLoading(false);
      }
    } catch (err) {
      console.log(err);
      setSubmissionLoading(false);
    }
    
    
  }

  function newModal() {
    setCreateModalOpen(true);
  }

  function closeNewModal() {
    setPostUrl('');
    setValidUrl(true);
    setCreateModalOpen(false);
  }

  const errorAction = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={()=>{handleCloseError()}}>
        
      </Button>
      <IconButton
        size="small"
        color="inherit"
        onClick={handleCloseError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  
  useEffect(() => {
    if (
      postUrl !== '' && 
      validUrl
    ) {
      setValidInputs(true);
      
    } else {setValidInputs(false);}
    
  },[postUrl,validUrl, createModalOpen]);


  return (
    <>
      {clickType === 'button' && 
        <Grid 
          container={true}
          spacing={0}
          direction="column"
          alignItems="flex-end"
          justifyContent="center">
            <Grid item xs={12}>
              <Button variant="outlined" color={theme.palette.mode === 'light' ? 'secondary' : 'primary'} startIcon={<AddIcon />} sx={{mr:10}} onClick={newModal}>
                Add Content
              </Button>
              {
                /*<IconButton sx={{mr:10}} aria-label="addRecord" color={theme.palette.mode === 'light' ? 'secondary' : 'primary'} onClick={newModal}>
                <AddIcon />
                </IconButton>*/
              }
            </Grid>          
        </Grid>
      }
      {clickType === 'chip' && 
        <Chip icon={<AddIcon />} onClick={newModal}  variant="outlined" clickable={true} sx={{ mx: 4, mt:2,mb:4,fontSize:12}}/>
      }   
      <Dialog open={createModalOpen} onClose={closeNewModal}>
        <DialogTitle>
          Add Content 
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeNewModal}
            sx={{float:'right'}}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <br />
          <Typography variant='caption'>Add a link to a content example to show brands what you can create.</Typography>
        </DialogTitle>
        <DialogContent>
          <Card sx={{ maxWidth: 1200 , minWidth: "25rem"}} className="transparent-background">
            <Box sx={{alignItems: 'flex-end'}}>
              <Box maxWidth="max" sx={{ flexDirection: 'row', p: 8}}> 
              {
                <>
                <Select
                  id="ugcType"
                  fullWidth
                  variant="standard"
                  value={ugcType}
                  label="UGC Type"                   
                  onChange={handleUgcTypeChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => {
                    return (
                      <Chip key={selected} label={ugcTypeMapping[selected]} />                       
                    )
                  }}
                  sx={{display: 'block', mx: "auto", width:500, mt:2}}
                >
                  {
                    Object.keys(ugcTypeMapping).map((oKey) => (
                      <MenuItem value={oKey} key={oKey}>{ugcTypeMapping[oKey]}</MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>UGC Type</FormHelperText>

                <Box sx={{ justifyContent: 'center'}}>
                  <TextField
                    id="postUrl"
                    variant="standard"
                    label="Post Url"
                    fullWidth
                    error={!validUrl}
                    value={postUrl}
                    onChange={handlePostUrlChange}
                    sx={{display: 'block', mx: "auto", width:500, mb:4}}
                  />    
                </Box>
              </>
              }
                
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <LoadingButton
                  component="span"
                  loading={submissionLoading}
                  disabled={!validInputs}
                  variant="contained"
                  onClick={upsertPortfolioItem}
                >
                  Save
                </LoadingButton>
              </Box>
            </Box>              
          </Card>
        </DialogContent>
      </Dialog>
      
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
        message={errorMessage}
        action={errorAction}
      />
      
    </>
  );
}

export default CreatePost;


